import styles from "./SchedulePage.module.css";
import React, { useEffect, useState } from "react";
import ScrollTopTop from "components/common/scrollToTop";
import Banner from "components/common/banner";
import { useHistory,useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "react-bootstrap";
import racer from "../../assets/home2/Cluster/Sample_User_Icon.png";
import india from "../../assets/home2/Cluster/india.png"

import "./style.css";

export const getPropertyNoCase = (obj, prop) => {
  return obj[
    Object.keys(obj)?.find((key) =>
      prop?.toLowerCase()?.includes(key.toLowerCase())
    )
  ];
};

function ScheduleAndResults({ children }) {

  const history = useHistory();
  const scheduleMenu = [
    { title: "Overview", path: "overview" },
    { title: "Daily", path: "daily" },
    { title: "Cluster", path: "cluster" }
  ];
  const [activeState, setActiveState] = useState("Daily");
  const location = useLocation();
  console.log("element--->",activeState)

    useEffect(() => {
    const path = location.pathname.split("/"); // Split the URL path
    const activePath = path[path.length - 1];  // Get the last part (e.g., 'daily', 'overview')
    
    if (activePath) {
      setActiveState(activePath.charAt(0).toUpperCase() + activePath.slice(1)); // Capitalize and set the state
    }
  }, [location.pathname]);  // Trigger this effect whenever the pathname changes

  const menuHandler = (title) => {
    // Update the URL when a new menu item is clicked
    history.push(`/schedule/${title?.toLowerCase()}`);
  };

  return (
    <ScrollTopTop>
      <div className={styles.page}>
        <Banner text="Schedule & Results" />
        <Row className="d-flex justify-content-center">
          <Col lg={11} className="" style={{position:'relative'}}>
          {/* <div className={styles.schedule_navbar}>{nav_element}</div> */}
          <Row className={styles.schedule_navbar}>
            <Col lg={4}>
              <img src={racer}/>
              <p>Official Technology Partner</p>
            </Col>
            <Col lg={4} className="d-flex align-items-center">
              <ul className="d-lg-flex justify-content-between w-100 shedule_active_menu_container align-items-center">
              {scheduleMenu.map((data) => (
                    <li
                      key={data.title}
                      className={`${activeState === data.title ? "schedule_active_menu" : "schedule__menu_title"}`}  // Apply active class conditionally
                      onClick={() => menuHandler(data.title)}
                    >
                      {data.title}
                    </li>
                  ))}
                
              </ul>
            </Col>
            <Col lg={4}>
              <img src={india}/>
              <p>Olympic Partner</p>
            </Col>
          </Row>
          </Col>
        </Row>

        {children}
      </div>
    </ScrollTopTop>
  );
}

export default ScheduleAndResults;
