export const footer1 = [
    { name: "Home", path: "/" },
    { name: "Schedule and Results", path: "" },
    { name: "Medal Tally", path: "/games/medals" },
    { name: "Visit", path: "/" },
    { name: "Teams", path: "/teams" },
    { name: "Milestones", path: "/milestone/all" },
    // { name: "Games Culture", path: "/milestone/all" },
  ]

  export const sportsOptions = [
    { name: "Aquatics", path: "" },
    { name: "Archery", path: "" },
    { name: "Athletics", path: "" },
    { name: "Badminton", path: "" },
    { name: "Basketball", path: "" },
    { name: "Boxing", path: "" },
    { name: "Cycling", path: "" },
    { name: "Equestrian", path: "" },
    { name: "Fencing", path: "" },
    { name: "Football", path: "" },
    { name: "Gymnastics", path: "" },
    { name: "Hockey", path: "" },
    { name: "Handball", path: "" },
    { name: "Judo", path: "" },
    { name: "Kabaddi", path: "" },
    { name: "Kayaking", path: "" },
    
  
  ];

  export const footer3 = [
    { name: "Kho-Kho", path: "" },
    { name: "Karate", path: "" },
    { name: "Lawn Ball", path: "" },
    { name: "Modern Pentathlon", path: "" },
    { name: "Mallakhamb", path: "" },
    { name: "Netball", path: "" },
    { name: "Rowing", path: "" },
    { name: "Rugby", path: "" },
    { name: "Shooting", path: "" },
    { name: "Squash", path: "" },
    { name: "Sailing", path: "" },
    { name: "Sepak Takraw", path: "" },
    { name: "Table Tennis", path: "" },
    { name: "Tennis", path: "" },
    { name: "Triathlon", path: "" },  
    { name: "Taekwondo", path: "" },
   
 
  ];
  

  export const footer4 = [
    { name: "Volleyball", path: "" }, 
    { name: "Weightlifting", path: "" },  
    { name: "Wushu", path: "" },  
    { name: "Wrestling", path: "" }, 
    { name: "Yoga", path: "" },
   

  ];