import olympicLogo from "../../assets/home2/header/olympic-games.png";
import swim from "../../assets/games-icons/swim.png";
import Overall from "../../assets/mediatallyicons/Overall.png";
import Hospitality from "../../assets/visit/Hospitality.png";
import KhelVillage from "../../assets/visit/khelVillage.svg";
import _36thNationalGames from "../../assets/visit/36 3.png";
import _37thNationalGames from "../../assets/visit/37.png";
import NationalGames from "../../assets/about-icons-svg/nationalGames.png";
import monal from "../../assets/about-icons-svg/monalMascot.png";
import contactUKGOC from "../../assets/about-icons-svg/contactUKGOC.png";
import Uttrakhand from "../../assets/about-icons-svg/Uttarakhand.png";
import IOA from "../../assets/about-icons-svg/IOA.png";
import Partners from "../../assets/about-icons-svg/Partners.png";
import Overview from "../../assets/schedule-overview-svg/scheduleOverview.svg";
import Cluster from "../../assets/schedule-overview-svg/Cluster.svg";
import Daily from "../../assets/schedule-overview-svg/Daily.svg";


// games
import records from "../../assets/games-icons/game_records.svg";
import game_reports from "../../assets/games-icons/game_reports.svg";
import mediatally from "../../assets/games-icons/game_mediaTally.svg";
import visit from "../../assets/games-icons/game_visit.svg";
import about from "../../assets/games-icons/game_about.svg";



// // scores
// import ArcheryShooting from "../../assets/scores-svg/Archery-Shhooting.svg";
// import ArcheryRunning from "../../assets/scores-svg/Archery-Running.svg";
// import Aquatics from "../../assets/scores-svg/Aquatics.svg";
// import Badminton from "../../assets/scores-svg/badminton.svg";
// import Basketball from "../../assets/scores-svg/Basketball.svg";
// import Boxing from "../../assets/scores-svg/Boxing.svg";
// import Cycling from "../../assets/scores-svg/cycling.svg";
// import Equestrian from "../../assets/scores-svg/Equestrian.svg";
// import Fencing from "../../assets/scores-svg/Fencing.svg";
// import Football from "../../assets/scores-svg/Football.svg";
// import golf from "../../assets/scores-svg/golf.svg";
// import gymnastics from "../../assets/scores-svg/gymnastics.svg";
// import handball from "../../assets/scores-svg/handball.svg";
// import hockey from "../../assets/scores-svg/hockey.svg";
// import judo from "../../assets/scores-svg/judo.svg";
// import kabaddi from "../../assets/scores-svg/kabbadi.svg";
// import khokho from "../../assets/scores-svg/khokho.svg";
// import lawnball from "../../assets/scores-svg/lawnball.svg";
// import Pentathlon from "../../assets/scores-svg/pentathlon.svg";
// import netball from "../../assets/scores-svg/netball.svg";
// import rowing from "../../assets/scores-svg/rowing.svg";
// import rugby from "../../assets/scores-svg/rugby.svg";
// import shooting from "../../assets/scores-svg/shooting.svg";
// import squash from "../../assets/scores-svg/squash.svg";
// import tabeltennis from "../../assets/scores-svg/tableTennis.svg";
// import tennis from "../../assets/scores-svg/tennis.svg";
// import triathlon from "../../assets/scores-svg/trithalon.svg";
// import volleyball from "../../assets/scores-svg/volleyball.svg";
// import weightlifting from "../../assets/scores-svg/weightLifiting.svg";
// import wushu from "../../assets/scores-svg/wushu.svg";
// import wrestling from "../../assets/scores-svg/wrestling.svg";
// import taekwondo from "../../assets/scores-svg/taekwondo.svg";
// import yoga from "../../assets/scores-svg/yoga.svg";
// import mallakhambh from "../../assets/scores-svg/mallakhabh.svg";
// import karate from "../../assets/scores-svg/karate.svg";
// import sportClimbing from "../../assets/scores-svg/sportClimbing.svg";
// import powerlifting from "../../assets/scores-svg/powerifting.svg";
// import canoeing from "../../assets/scores-svg/canoeing.svg";
// import Sailing from "../../assets/scores-svg/saling.svg";
// import beachVolleyball from "../../assets/scores-svg/beachVolleyball.svg";
// import sepakTakrow from "../../assets/scores-svg/sepakTakraw.svg";
// import Indigenous from "../../assets/scores-svg/Indigenous.svg";
import ArcheryShooting from "../../assets/scores-png/Archery-Shhooting.png";
import ArcheryRunning from "../../assets/scores-png/Archery-Running.png";
import Aquatics from "../../assets/scores-png/Aquatics.png";
import Badminton from "../../assets/scores-png/badminton_white 1.png";
import Basketball from "../../assets/scores-png/Basketball.png";
import Boxing from "../../assets/scores-png/Boxing.png";
import Cycling from "../../assets/scores-png/cycling.png"
import Equestrian from "../../assets/scores-png/Equestrian.png";
import Fencing from "../../assets/scores-png/Fencing.png";
import Football from "../../assets/scores-png/Football.png";
import golf from "../../assets/scores-png/golf.png";
import gymnastics from "../../assets/scores-png/gymnastics.png";
import handball from "../../assets/scores-png/handball.png";
import hockey from "../../assets/scores-png/hockey.png";
import judo from "../../assets/scores-png/judo.png";
import kabaddi from "../../assets/scores-png/Kabaddi.png";
import khokho from "../../assets/scores-png/Kho-Kho.png";
import lawnball from "../../assets/scores-png/lawnball.png";
import Pentathlon from "../../assets/scores-png/penthalon.png";
import netball from "../../assets/scores-png/netball.png";
import rowing from "../../assets/scores-png/rowing.png";
import rugby from "../../assets/scores-png/rugby.png";
import shooting from "../../assets/scores-png/shooting.png";
import squash from "../../assets/scores-png/squash.png";
import tabeltennis from "../../assets/scores-png/tableTennis.png";
import tennis from "../../assets/scores-png/tennis.png";
import triathlon from "../../assets/scores-png/trithalon.png";
import volleyball from "../../assets/scores-png/volleyball.png";
import weightlifting from "../../assets/scores-png/Weightlifting.png";
import wushu from "../../assets/scores-png/wushu.png";
import wrestling from "../../assets/scores-png/wrestling.png";
import taekwondo from "../../assets/scores-png/taekwondo.png";
import yoga from "../../assets/scores-png/yoga.png";
import mallakhambh from "../../assets/scores-png/mallkhamb.png";
import karate from "../../assets/scores-png/karate.png";
import sportClimbing from "../../assets/scores-png/sportClimbing.png";
import powerlifting from "../../assets/scores-png/powerifting.png";
import canoeing from "../../assets/scores-png/kayaking.png";
import Sailing from "../../assets/scores-png/Sailing.png";




// latest
import news from "../../assets/latest/news.svg";
import gallery from "../../assets/latest/gallery.svg";
import videos from "../../assets/latest/videos.svg";

// milestones

// import torch from "../../assets/mil-stone-svg/torch.svg";
// import allMilestone from "../../assets/mil-stone-svg/allMilestone.svg";
// import IOAAnnounces from "../../assets/mil-stone-svg/IOA_Announces.svg";
// import NG38 from "../../assets/mil-stone-svg/NG38.svg";
// import FlagPassed from "../../assets/mil-stone-svg/flagPassed.svg";
// import logolaunch from "../../assets/mil-stone-svg/logo_launch.svg";
import torch from "../../assets/mil-stone-png/torch.png";
import allMilestone from "../../assets/mil-stone-png/allMilestone.png";
import IOAAnnounces from "../../assets/about-icons-svg/IOA.png";
import NG38 from "../../assets/mil-stone-png/38NG.png";
import flagPassed from "../../assets/mil-stone-png/logo.png";
import logolaunch from "../../assets/mil-stone-png/Rocket 2.png";
import Rocket3 from "../../assets/mil-stone-png/Rocket3.png"
import rocket4 from "../../assets/mil-stone-png/rocket4.svg"
import Medal from "../../assets/mil-stone-png/Medal Star.svg"
import Victory from "../../assets/mil-stone-png/Cup Star.svg"

// // teams
// import allTeams from "../../assets/teams-svg/allTeams.svg";
// import teamstates from "../../assets/teams-svg/teamStates.svg";
// import teamUT from "../../assets/teams-svg/TeamUT.svg";
// import sportsBoard from "../../assets/teams-svg/TeamSportsBoard.svg";

// // teams => states
// import andaman_nicobar from "../../assets/teams-svg/andaman_nicobar.svg";
// import andhra_pradesh from "../../assets/teams-svg/andhra_pradesh.svg";
// import arunachal_pradesh from "../../assets/teams-svg/arunachal_pradesh.svg";
// import assam from "../../assets/teams-svg/assam.svg";
// import bihar from "../../assets/teams-svg/Bihar.svg";
// import chandigarh from "../../assets/teams-svg/chandigarh.svg";
// import chattisgarh from "../../assets/teams-svg/chattisgarh.svg";
// import dadraAndnagarhaveli from "../../assets/teams-svg/DadraAndNagarHaveli.svg";
// import delhi from "../../assets/teams-svg/Delhi.svg";
// import goa from "../../assets/teams-svg/Goa.svg";
// import gujarat from "../../assets/teams-svg/Gujarat.svg";
// import haryana from "../../assets/teams-svg/Haryana.svg";
// import himanchal from "../../assets/teams-svg/Himanchal_Pradesh.svg";
// import J_K from "../../assets/teams-svg/J&K.svg";
// import Jharkhand from "../../assets/teams-svg/jharkhand.svg";
// import KRNTK from "../../assets/teams-svg/karanataka.svg";
// import KERELA from "../../assets/teams-svg/Kerela.svg";
// import ladakh from "../../assets/teams-svg/ladakh.svg";
// import Lakshadweep from "../../assets/teams-svg/lakshadweep.svg";
// import MP from "../../assets/teams-svg/MP.svg";
// import MH from "../../assets/teams-svg/MH.svg";
// import manipur from "../../assets/teams-svg/Manipur.svg";
// import Meghalaya from "../../assets/teams-svg/Meghalaya.svg";
// import Mizoram from "../../assets/teams-svg/mizoram.svg";
// import Nagaland from "../../assets/teams-svg/Nagaland.svg";
// import Odisha from "../../assets/teams-svg/Odisha.svg";
// import Puducherry from "../../assets/teams-svg/puducherry.svg";
// import punjab from "../../assets/teams-svg/punjab.svg";
// import rajasthan from "../../assets/teams-svg/rajasthan.svg";
// import sikkim from "../../assets/teams-svg/sikkim.svg";
// import sscb from "../../assets/teams-svg/sscb.svg";
// import TN from "../../assets/teams-svg/TN.svg";
// import Telangana from "../../assets/teams-svg/telangana.svg";
// import Tripura from "../../assets/teams-svg/tripura.svg";
// import UP from "../../assets/teams-svg/UP.svg";
// import UK from "../../assets/teams-svg/UK.svg";
// import WB from "../../assets/teams-svg/WB.svg";

// teams
import allTeams from "../../assets/teamsPng/allTeams.png";
import teamstates from "../../assets/teamsPng/teamStates.png";
import TeamUT from "../../assets/teamsPng/TeamUT.png";
import SportsBoard from "../../assets/teamsPng/TeamSportsBoard.png";

// teams => states
import andaman_nicobar from "../../assets/teamsPng/andaman_nicobar.png";
import andhra_pradesh from "../../assets/teamsPng/andhra_pradesh.png";
import arunachal_pradesh from "../../assets/teamsPng/arunachal_pradesh.png";
import assam from "../../assets/teamsPng/assam.png";
import bihar from "../../assets/teamsPng/Bihar.png";
import chandigarh from "../../assets/teamsPng/chandigarh.png";
import chattisgarh from "../../assets/teamsPng/chattisgarh.png";
import DadraAndnagarhaveli from "../../assets/teamsPng/DadraAndNagarHaveli.png";
import Delhi from "../../assets/teamsPng/Delhi.png";
import Goa from "../../assets/teamsPng/Goa.png";
import Gujarat from "../../assets/teamsPng/Gujarat.png";
import Haryana from "../../assets/teamsPng/Haryana.png";
import Himanchal from "../../assets/teamsPng/Himachal Pradesh.png";
import J_K from "../../assets/teamsPng/J&K.png";
import jharkhand from "../../assets/teamsPng/jharkhand.png";
import KRNTK from "../../assets/teamsPng/Karnataka.png";
import KERELA from "../../assets/teamsPng/Kerala.png";
import ladakh from "../../assets/teamsPng/ladakh.png";
import Lakshadweep from "../../assets/teamsPng/lakshadweep.png"
import MP from "../../assets/teamsPng/MP.png";
import MH from "../../assets/teamsPng/MH.png";
import Manipur from "../../assets/teamsPng/Manipur.png";
import Meghalaya from "../../assets/teamsPng/Meghalaya.png";
import Mizoram from "../../assets/teamsPng/mizoram.png";
import Nagaland from "../../assets/teamsPng/Nagaland.png";
import Odisha from "../../assets/teamsPng/Odisha.png";
import Puducherry from "../../assets/teamsPng/puducherry.png";
import punjab from "../../assets/teamsPng/punjab.png";
import rajasthan from "../../assets/teamsPng/rajasthan.png";
import sikkim from "../../assets/teamsPng/sikkim.png";
import sscb from "../../assets/teamsPng/sscb.png";
import TN from "../../assets/teamsPng/TN.png";
import Telangana from "../../assets/teamsPng/telangana.png";
import Tripura from "../../assets/teamsPng/tripura.png";
import UP from "../../assets/teamsPng/UP.png";
import UK from "../../assets/teamsPng/UK.png";
import WB from "../../assets/teamsPng/WB.png";


export const games_data = [
  {
    img: records,
    value: "records",
    heading: "RECORDS",
    link: "/games/records",
    description:
      "The Records section of the 38th National Games, Uttarakhand, serves as a testament to the extraordinary achievements of our athletes. Here, you will find a comprehensive compilation of all the records set and broken during the Games.",
    sideOptions: [],
  },
  {
    img: game_reports,
    value: "reports",
    heading: "REPORTS",
    link: "/games/reports",
    description:
      "Stay informed with our detailed Reports section, providing in-depth analysis and insights into the events, performances, and organizational aspects of the 38th National Games.",
  },
  {
    img: mediatally,
    value: "medallTally",
    heading: "MEDAL TALLY",
    link: "/games/medals",
    description:
      "The overall medal tally provides a comprehensive summary of the total medals won by each state, union territory, and sports board across all sports and disciplines in the National Games. This tally reflects the cumulative performance and showcases the dominant regions in terms of athletic excellence and competitiveness.",
    sideOptions: [
      {
        image: Overall,
        name: "Overall",
        link: { isRelativePath: true, path: "/" },

        description:
          "The overall medal tally provides a comprehensive summary of the total medals won by each state, union territory, and sports board across all sports and disciplines in the National Games. This tally reflects the cumulative performance and showcases the dominant regions in terms of athletic excellence and competitiveness.",
      },
    ],
  },
  {
    img: visit,
    value: "visit",
    heading: "VISIT",
    link: "/",

    description:
      "Whether you are a spectator, supporter, or participant, this section provides all the essential information you need to make your experience seamless and enjoyable.",
    sideOptions: [
      {
        image: Hospitality,
        name: "Hospitality",
        link: { isRelativePath: true, path: "/visit/hospitality" },
        description:
          "Uttarakhand is renowned for its warm hospitality and welcoming spirit.",
      },
      // {
      //   image: KhelVillage,
      //   name: "Khel Village",
      //   link: { isRelativePath: true, path: "/" },

      //   description:
      //     "The Khel Village is the heart of the 38th National Games, designed to foster a sense of community and camaraderie among athletes, coaches, and officials.",
      // },
      {
        image: _36thNationalGames,
        name: "36 National Games",
        link: { isRelativePath: true, path: "/visit/36-national-games" },
        description:
          "The 36th National Games, held in Gujrat, set a benchmark for excellence with its vibrant display of athletic talent and organizational prowess.",
      },
      {
        image: _37thNationalGames,
        name: "37 National Games",
        link: { isRelativePath: false, path: "https://37nationalgamesgoa.in/" },

        description:
          "The 37th National Games, hosted by Goa, was another milestone in the history of Indian sports. The event was marked by thrilling competitions, record-breaking performances, and the spirit of unity among participants.",
      },
    ],
  },
  {
    img: about,
    value: "about",
    heading: "ABOUT",
    link: "/info/about-national-games",
    description:
      "Learn more about the vision, mission, and history of the  National Games in our About section. Here, we delve into the significance of the Games, the organizing committee's efforts, and the impact on the sporting community and beyond",
    sideOptions: [
      {
        image: NationalGames,
        name: "National Games",
        link: { isRelativePath: true, path: "/info/about-national-games" },
        description:
          "The National Games of India, a prestigious multi-sport event, brings together athletes from across the nation to compete in a celebration of excellence, sportsmanship, and unity.",
      },
      {
        image: monal,
        name: "Mauli(Mascot) ",
        link: { isRelativePath: true, path: "/info/about-mauli" },
        description:
          "Mauli, the vibrant and colorful state bird of Uttarakhand, has been chosen as the official mascot for the 38th National Games.",
      },

      {
        image: contactUKGOC,
        name: "Contact UKGOC",
        link: { isRelativePath: true, path: "/" },
        description:
          'The UKNGOC (Uttarakhand National Games Organising Committee) is the central body responsible for overseeing the planning, coordination, and execution of the 38th National Games of India in Uttarakhand.',
      },
      {
        image: Uttrakhand,
        name: "Uttarakhand",
        link: { isRelativePath: true, path: "/info/about-uttrakhand" },
        description:
          'Nestled in the lap of the Himalayas, Uttarakhand is a land of natural beauty, rich cultural heritage, and adventure. Known as the "Land of Gods," it is home to sacred shrines, lush green valleys, and majestic peaks.',
      },
      {
        image: IOA,
        name: "IOA",
        link: { isRelativePath: true, path: "/info/about-ioa" },
        description:
          "The Indian Olympic Association (IOA) is the governing body responsible for the promotion and development of the Olympic movement and sports in India.",
      },

      {
        image: Partners,
        name: "Partners",
        link: { isRelativePath: true, path: "/" },
        description:
          "Partners ensure the Collaborative Coordiation at the 38th National Games Of India, Uttarakhand",
      },
      {
        image: "/logo/white_updated.png",
        name: "Logo",
        link: { isRelativePath: true, path: "/info/logo" },
        description:
          "The logo reflects the spirit, culture, and natural beauty of the host state while encapsulating the essence of national unity and sporting excellence.",
      },
    ],
  },

  {
    img: records,
    value: "National Games",
    heading: "National Games",
    link: "/info/about-national-games",
    description:
      "The National Games of India, a prestigious multi-sport event, brings together athletes from across the nation to compete in a celebration of excellence, sportsmanship, and unity.",
    sideOptions: [],
  },

  {
    img: records,
    value: "Mauli(Mascot)",
    heading: "Mauli(Mascot)",
    link: "/info/about-mauli",
    description:
      "Mauli, the vibrant and colorful state bird of Uttarakhand, has been chosen as the official mascot for the 38th National Games.",
    sideOptions: [],
  },
  {
    img: records,
    value: "Contact UKGOC",
    heading: "Contact UKGOC",
    link: "/",
    description:
      "The UKNGOC (Uttarakhand National Games Organising Committee) is the central body responsible for overseeing the planning, coordination, and execution of the 38th National Games of India in Uttarakhand.",
    sideOptions: [],
  },
  {
    img: records,
    value: "Uttarakhand",
    heading: "Uttarakhand",
    link: "/info/about-uttrakhand",
    description:
      "Nestled in the lap of the Himalayas, Uttarakhand is a land of natural beauty, rich cultural heritage, and adventure. Known as the Land of Gods, it is home to sacred shrines, lush green valleys, and majestic peaks.",
    sideOptions: [],
  },
  {
    img: records,
    value: "IOA",
    heading: "IOA",
    link: "/info/about-ioa",
    description:
      "The Indian Olympic Association (IOA) is the governing body responsible for the promotion and development of the Olympic movement and sports in India.",
    sideOptions: [],
  },
  {
    img: records,
    value: "Partners",
    heading: "Partners",
    link: "/",
    description:
      "Partners ensure the Collaborative Coordiation at the 38th National Games Of India, Uttarakhand",
    sideOptions: [],
  },
  {
    img: records,
    value: "Logo",
    heading: "Logo",
    link: "/info/logo",
    description:
      "The logo reflects the spirit, culture, and natural beauty of the host state while encapsulating the essence of national unity and sporting excellence.",
    sideOptions: [],
  },
];

export const schedule_data = [
  {
    img: Overview,
    value: "overview",
    heading: "Overview",
    link: "/",
    description:
      "The Overall Schedule provides a comprehensive overview of all events, competitions, and activities taking place during the National Games. This schedule allows participants, spectators, and officials to plan their time effectively, ensuring they don't miss any of the key events across all sports and venues.",
    sideOptions: [],
  },
  {
    img: Daily,
    value: "daily",
    heading: "Daily",
    link: "",
    description:
      "The Daily Schedule outlines the specific events happening each day of the National Games. It breaks down the competitions, ceremonies, and other activities by time and location, helping attendees stay organized and informed about what's happening on any given day.",
  },
  {
    img: Cluster,
    value: "cluster",
    heading: "Cluster",
    link: "/",
    description:
      "The Cluster Schedule categorizes events based on their respective sports or venue clusters. This schedule is ideal for those focusing on specific sports or venues, allowing them to follow the progression of events within a particular cluster throughout the duration of the Games.",
  },
];

export const sportList = [
  {
    image: Aquatics,
    name: "Aquatics",
    link: { path: "", isRelativePath: true },
    description:
      "Water-based sports including swimming, diving, synchronized swimming, and water polo.",
  },


  
  {
    image: ArcheryShooting,
    name: "Archery",
    link: { path: "", isRelativePath: true },
    description:
      "Archery is the sport of shooting arrows at a target for accuracy from a set distance",
  },
  {
    image: ArcheryRunning,
    name: "Athletics",
    link: { path: "/sports/athletics", isRelativePath: true },
    description:
      "Athletics is a collection of sports events that include running, jumping, throwing, and walking disciplines.",
  },
  {
    image: Badminton,
    name: "Badminton",
    link: { path: "/sports/badminton", isRelativePath: true },
    description:
      "Badminton is a racquet sport played with a shuttlecock across a net.",
  },
  {
    image: Basketball,
    name: "Basketball",
    link: { path: "/sports/basketball", isRelativePath: true },
    description:
      "A team sport where players aim to score by shooting a ball through the opponent's hoop.",
  },
  {
    image: canoeing,
    name: "Kayaking and Canoeing",
    link: { path: "/sports/canoeing", isRelativePath: true },
    description:
      "paddling a canoe with a single-bladed paddle, either in calm waters or racing against others.",
  },

  {
    image: Boxing,
    name: "Boxing",
    link: { path: "/sports/boxing", isRelativePath: true },
    description:
      "A Combat sport where two participants fight using their fists, aiming to land punches while avoiding their opponent's blows.",
  },

  {
    image: Cycling,
    name: "Cycling",
    link: { path: "/sports/cycling", isRelativePath: true },
    description: "Sport of riding bicycles, either on roads or trails.",
  },
  {
    image: Equestrian,
    name: "Equestrian",
    link: { path: "/sports/equestrian", isRelativePath: true },
    description:
      "Horseback riding and include disciplines such as dressage, show jumping, and eventing.",
  },
  {
    image: Fencing,
    name: "Fencing",
    link: { path: "/sports/fencing", isRelativePath: true },
    description:
      "A combat sport where competitors fight with swords aiming to score points by making contact with their opponent.",
  },
  {
    image: Football,
    name: "Football",
    link: { path: "/sports/football", isRelativePath: true },
    description:
      "Team sport where players aim to score by getting a ball into the opponent's goal.",
  },
  {
    image: golf,
    name: "Golf",
    link: { path: "/sports/golf", isRelativePath: true },
    description:
      "Sport where players use clubs to hit a ball into a series of holes on a course in as few strokes as possible.",
  },
  {
    image: gymnastics,
    name: "Gymnastics",
    link: { path: "/sports/gymnastics", isRelativePath: true },
    description:
      "Gymnastics involves performing routines on different apparatuses, showcasing strength, flexibility, and coordination.",
  },
  {
    image: handball,
    name: "Handball",
    link: { path: "/sports/handball", isRelativePath: true },
    description:
      "Team sport where players aim to score by throwing a ball into the opponent's goal.",
  },
  {
    image: hockey,
    name: "Hockey",
    link: { path: "/sports/hockey", isRelativePath: true },
    description:
      "Team sport played on the field where players use sticks to hit a ball into the opponent's goal.",
  },
  {
    image: judo,
    name: "Judo",
    link: { path: "/sports/judo", isRelativePath: true },
    description:
      "Martial art and Olympic sport where competitors aim to throw or grapple their opponent to the ground.",
  },
  {
    image: kabaddi,
    name: "Kabaddi",
    link: { path: "/sports/kabaddi", isRelativePath: true },
    description: `A contact team sport originating from India, where players take turns sending a "raider" into the opposing team's half to tag as many defenders as possible and return without being tackled.`,
  },
  {
    image: khokho,
    name: "Kho-Kho",
    link: { path: "/sports/khokho", isRelativePath: true },
    description: `A traditional Indian tag sport where teams alternate between chasing and being chased, aiming to tag out as many opponents as possible.`,
  },
  {
    image: lawnball,
    name: "Lawn ball",
    link: { path: "/sports/lawnball", isRelativePath: true },
    description:
      "A traditional Indian tag sport where teams alternate between chasing and being chased, aiming to tag out as many opponents as possible",
  },
  {
    image: Pentathlon,
    name: "Modern Pentathlon",
    link: { path: "/sports/pentathlon", isRelativePath: true },
    description:
      "Modern Pentathlon is an Olympic sport combining five disciplines: fencing, freestyle swimming, show jumping, and a combined event of pistol shooting and cross-country running.",
  },
  {
    image: netball,
    name: "Netball",
    link: { path: "/sports/netball", isRelativePath: true },
    description:
      "Netball is a fast-paced team sport primarily played by women. Seven players per team aim to score goals by passing a ball and shooting it through a raised hoop.",
  },
  {
    image: rowing,
    name: "Rowing",
    link: { path: "/sports/rowing", isRelativePath: true },
    description:
      "Rowing is a water sport where athletes propel boats using oars. It combines strength, endurance, and technique.",
  },
  {
    image: Sailing,
    name: "Sailing",
    link: { path: "/sports/sailing", isRelativePath: true },
    description:
      "Sailing is the sport of navigating a boat using wind to propel it",
  },
  {
    image: rugby,
    name: "Rugby Sevens",
    link: { path: "/sports/rugby", isRelativePath: true },
    description:
      "Rugby Sevens is a variant of rugby union with seven players per team and shorter matches",
  },

  {
    image: shooting,
    name: "Shooting",
    link: { path: "/sports/shooting", isRelativePath: true },
    description:
      "Precision sport involving the use of firearms or airguns to hit targets at various distances.",
  },
  {
    image: squash,
    name: "Squash",
    link: { path: "/sports/squash", isRelativePath: true },
    description:
      "Squash is a fast-paced racquet sport played in an enclosed court. Two or four players hit a small rubber ball against the front wall, aiming to outmaneuver opponents.",
  },
  {
    image: tabeltennis,
    name: "Table Tennis",
    link: { path: "/sports/table-tennis", isRelativePath: true },
    description:
      "Fast-paced indoor sport where players hit a lightweight ball across a table using small paddles.",
  },
  {
    image: tennis,
    name: "Tennis",
    link: { path: "/sports/tennis", isRelativePath: true },
    description:
      "A Racquet sport played individually or in pairs, aiming to hit a ball over a net into the opponent's court.",
  },

  {
    image: triathlon,
    name: "Triathlon",
    link: { path: "/sports/triathlon", isRelativePath: true },
    description:
      "A Korean martial art focused on high, fast kicks and quick footwork.",
  },

  {
    image: volleyball,
    name: "Volleyball",
    link: { path: "/sports/volleyball", isRelativePath: true },
    description:
      "Team sport where players aim to hit a ball over a net and onto the floor of the opponent's court.",
  },
  // {
  //   image: beachVolleyball,
  //   name: "Beach Volleyball",
  //   link: { path: "/sports/beach", isRelativePath: true },
  //   description:
  //     "Similar to indoor volleyball but played on sand with teams of two players.",
  // },

  {
    image: weightlifting,
    name: "Weightlifting",
    link: { path: "/sports/weightlifting", isRelativePath: true },
    description:
      "Strength sport where athletes lift heavy weights in two main lifts: the snatch and the clean and jerk.",
  },
  {
    image: wushu,
    name: "Wushu",
    link: { path: "/sports/wushu", isRelativePath: true },
    description:
      "A Korean martial art focused on high, fast kicks and quick footwork.",
  },
  {
    image: wrestling,
    name: "Wrestling",
    link: { path: "/sports/wrestling", isRelativePath: true },
    description:
      "Combat sport where competitors grapple to gain a superior position and pin their opponent.",
  },
  {
    image: taekwondo,
    name: "Taekwondo",
    link: { path: "/sports/taekwondo", isRelativePath: true },
    description:
      "Korean martial art focused on high, fast kicks and quick footwork.",
  },
  {
    image: yoga,
    name: "Yogasana",
    link: { path: "/sports/yoga", isRelativePath: true },
    description:
      "A team sport where players aim to hit a ball over a net and onto the floor of the opponent's court.",
  },
  {
    image: mallakhambh,
    name: "Mallakhamb",
    link: { path: "/sports/mallakhambh", isRelativePath: true },
    description:
      "A multi-discipline sport consisting of swimming, cycling, and running in immediate succession.",
  },
  {
    image: karate,
    name: "Karate",
    link: { path: "/sports/karate", isRelativePath: true },
    description:
      "A team sport where players aim to hit a ball over a net and onto the floor of the opponent's court.",
  },
  // {
  //   image: sportClimbing,
  //   name: "Sports Climbing",
  //   link: { path: "/sports/sport-climbing", isRelativePath: true },
  //   description:
  //     "Similar to indoor volleyball but played on sand with teams of two players.",
  // },
  // {
  //   image: powerlifting,
  //   name: "Powerlifting",
  //   link: { path: "/sports/powerlifting", isRelativePath: true },
  //   description:
  //     "Similar to indoor volleyball but played on sand with teams of two players.",
  // },
];

export const IndigenousportsList = [
  {
    image: kabaddi,
    name: "Kabaddi",
    link: { path: "/sports/kabaddi", isRelativePath: true },
    description: `A contact team sport originating from India, where players take turns sending a "raider" into the opposing team's half to tag as many defenders as possible and return without being tackled.`,
  },
  {
    image: khokho,
    name: "Kho-Kho",
    link: { path: "/sports/kho-kho", isRelativePath: true },
    description: `A traditional Indian tag sport where teams alternate between chasing and being chased, aiming to tag out as many opponents as possible`,
  },
  {
    image: mallakhambh,
    name: "Mallakhamb",
    link: { path: "/sports/sepak-takraw", isRelativePath: true },
    description: `An ancient Indian sport combining gymnastics and wrestling, where athletes perform aerial yoga-like poses and acrobatics on a vertical wooden pole or rope, showcasing strength, flexibility, and balance.`,
  },
  {
    image: wushu,
    name: "Wushu",
    link: { path: "/sports/wushu", isRelativePath: true },
    description: `A Chinese martial art incorporating a range of traditional and modern techniques, including forms (taolu) and sparring (sanshou). It blends athleticism with artistic expression, emphasizing fluid movements and discipline`,
  },
  {
    image: yoga,
    name: "Yogasana",
    link: { path: "/sports/yoga", isRelativePath: true },
    description: `Competitive practice of yoga postures, focusing on strength, flexibility, and balance. Athletes perform a series of poses, judged on form, grace, and control, blending tradition with competition.`,
  },

  



  
  
];

export const teams_data = [
  {
    img: allTeams,
    value: "All Teams",
    heading: "All Teams",
    link: "/teams",
    description:
      `The "All Teams" section of the 38th National Games Uttarakhand website will provide a comprehensive view of each participating team, including an introduction, detailed team profiles, and information on the sports they are competing in.`,

    sideOptions: [
      {
        image: andaman_nicobar,
        value: "Andaman and Nicobar Islands",
        heading: "Andaman and Nicobar Islands",
        link: `/teams/${formatLink("Andaman and Nicobar Islands")}`,
        description:
          "Andaman and Nicobar Islands excel in water sports, leveraging their pristine beaches and clear waters to develop activities like scuba diving, snorkeling, and sailing..",
        sideOptions: [],
      },
      {
        image: andhra_pradesh,
        value: "Andhra Pradesh",
        heading: "Andhra Pradesh",
        link: `/teams/${formatLink("Andhra Pradesh")}`,
        description:
          "Andhra Pradesh is known for its rich cultural heritage and vibrant sports community, excelling in athletics and badminton.",
        sideOptions: [],
      },
      {
        image: arunachal_pradesh,
        value: "Arunachal Pradesh",
        heading: "Arunachal Pradesh",
        link: `/teams/${formatLink("Arunachal Pradesh")}`,
        description:
          "Arunachal Pradesh boasts a diverse landscape, fostering a range of sports like archery and football amidst its natural beauty.",
        sideOptions: [],
      },
      {
        image: assam,
        value: "Assam",
        heading: "Assam",
        link: `/teams/${formatLink("Assam")}`,
        description:
          "Assam has a strong sporting tradition, particularly in sports like boxing and weightlifting, producing several national champions.",
        sideOptions: [],
      },
      {
        image: bihar,
        value: "Bihar",
        heading: "Bihar",
        link: `/teams/${formatLink("Bihar")}`,
        description:
          "Bihar is making strides in sports development, with a growing emphasis on athletics and wrestling among its youth.",
        sideOptions: [],
      },
      {
        image: chandigarh,
        value: "chandigarh",
        heading: "Chandigarh",
        link: `/teams/${formatLink("chandigarh")}`,
        description:
          "Chandigarh excels in athletics and hockey, benefiting from its well-planned infrastructure and sports facilities that nurture talent in these disciplines..",
        sideOptions: [],
      },
      {
        image: chattisgarh,
        value: "Chhattisgarh",
        heading: "Chhattisgarh",
        link: `/teams/${formatLink("chattisgarh")}`,
        description:
          "Chhattisgarh's sports culture is evolving, with a focus on sports like hockey and archery, reflecting its tribal heritage.",
        sideOptions: [],
      },
      {
        image: DadraAndnagarhaveli,
        value: "Dadra and Nagar Haveli and Daman and Diu",
        heading: "Dadra and Nagar Haveli and Daman and Diu",
        link: `/teams/${formatLink(
          "Dadra and Nagar Haveli and Daman and Diu"
        )}`,
        description:
          "Dadra and Nagar Haveli and Daman and Diu excel in traditional sports and athletics, utilizing community participation and local enthusiasm to foster a strong sports culture.",
        sideOptions: [],
      },
      {
        image: Delhi,
        value: "Delhi",
        heading: "Delhi",
        link: `/teams/${formatLink("Delhi")}`,
        description:
          "Delhi, the capital city, is a hub for various sports, excelling in athletics, cricket, and boxing, with state-of-the-art facilities.",
        sideOptions: [],
      },
      {
        image: Goa,
        value: "Goa",
        heading: "Goa",
        link: `/teams/${formatLink("Goa")}`,
        description:
          "Goa is renowned for its football fervor, producing talented players and teams that compete at national and international levels.",
        sideOptions: [],
      },
      {
        image: Gujarat,
        value: "Gujarat",
        heading: "Gujarat",
        link: `/teams/${formatLink("Gujarat")}`,
        description:
          "Gujarat has a dynamic sports environment, with strengths in cricket, athletics, and traditional sports like kabaddi.",
        sideOptions: [],
      },
      {
        image: Haryana,
        value: "Haryana",
        heading: "Haryana",
        link: `/teams/${formatLink("Haryana")}`,
        description:
          "Haryana is a powerhouse in sports, especially wrestling, boxing, and kabaddi, producing many Olympians and champions.",
        sideOptions: [],
      },
      {
        image: Himanchal,
        value: "Himachal Pradesh",
        heading: "Himachal Pradesh",
        link: `/teams/${formatLink("Himachal Pradesh")}`,
        description:
          "Himachal Pradesh excels in winter sports and athletics, leveraging its mountainous terrain for sports development.",
        sideOptions: [],
      },
      {
        image: J_K,
        value: "Jammu & Kashmir",
        heading: "Jammu & Kashmir",
        link: `/teams/${formatLink("Jammu & Kashmir")}`,
        description:
          "Jammu & Kashmir is known for winter sports like skiing and ice hockey, along with a growing interest in cricket and football.",
        sideOptions: [],
      },
      {
        image: jharkhand,
        value: "Jharkhand",
        heading: "Jharkhand",
        link: `/teams/${formatLink("Jharkhand")}`,
        description:
          "Jharkhand has a rich tradition in sports like hockey and archery, producing many athletes who excel on national stages.",
        sideOptions: [],
      },
      {
        image: KRNTK,
        value: "Karnataka",
        heading: "Karnataka",
        link: `/teams/${formatLink("Karnataka")}`,
        description:
          "Karnataka is a leader in various sports, including cricket, badminton, and swimming, with a robust sports infrastructure.",
        sideOptions: [],
      },
      {
        image: KERELA,
        value: "Kerala",
        heading: "Kerala",
        link: `/teams/${formatLink("Kerala")}`,
        description:
          "Kerala is known for its excellence in athletics, football, and volleyball, consistently producing top-tier athletes.",
        sideOptions: [],
      },
      {
        image: ladakh,
        value: "Ladakh",
        heading: "Ladakh",
        link: `/teams/${formatLink("ladakh")}`,
        description:
          "Ladakh excels in adventure sports and archery, using its high-altitude environment to develop sports that reflect its unique cultural and geographical context.",
        sideOptions: [],
      },
      {
        image: Lakshadweep,
        value: "Lakshadweep",
        heading: "Lakshadweep",
        link: `/teams/${formatLink("Lakshadweep")}`,
        description:
          "Lakshadweep excels in water sports, making the most of its natural aquatic environment to develop and promote activities such as kayaking, windsurfing, and diving.",
        sideOptions: [],
      },
      {
        image: MP,
        value: "Madhya Pradesh",
        heading: "Madhya Pradesh",
        link: `/teams/${formatLink("Madhya Pradesh")}`,
        description:
          "Madhya Pradesh has a vibrant sports scene, particularly in archery, athletics, and hockey, fostering young talent.",
        sideOptions: [],
      },
      {
        image: MH,
        value: "Maharashtra",
        heading: "Maharashtra",
        link: `/teams/${formatLink("Maharashtra")}`,
        description:
          "Maharashtra excels in a wide range of sports, from cricket and badminton to athletics, supported by excellent facilities.",
        sideOptions: [],
      },
      {
        image: Manipur,
        value: "Manipur",
        heading: "Manipur",
        link: `/teams/${formatLink("Manipur")}`,
        description:
          "Manipur is a sports powerhouse in the Northeast, known for producing top athletes in boxing, weightlifting, and football.",
        sideOptions: [],
      },
      {
        image: Meghalaya,
        value: "Meghalaya",
        heading: "Meghalaya",
        link: `/teams/${formatLink("Meghalaya")}`,
        description:
          "Meghalaya is passionate about football and archery, with a strong community support for developing sports talent.",
        sideOptions: [],
      },
      {
        image: Mizoram,
        value: "Mizoram",
        heading: "Mizoram",
        link: `/teams/${formatLink("Mizoram")}`,
        description:
          "Mizoram has a vibrant sports culture, particularly excelling in football and hockey, with a strong grassroots development.",
        sideOptions: [],
      },
      {
        image: Nagaland,
        value: "Nagaland",
        heading: "Nagaland",
        link: `/teams/${formatLink("Nagaland")}`,
        description:
          "Nagaland is making its mark in sports like archery, football, and athletics, with increasing participation and success.",
        sideOptions: [],
      },
      {
        image: Odisha,
        value: "Odisha",
        heading: "Odisha",
        link: `/teams/${formatLink("Odisha")}`,
        description:
          "Odisha is known for its strong hockey tradition, as well as growing prominence in athletics and archery.",
        sideOptions: [],
      },
      {
        image: Puducherry,
        value: "Puducherry",
        heading: "Puducherry",
        link: `/teams/${formatLink("Puducherry")}`,
        description:
          "Puducherry excels in beach volleyball and athletics, leveraging its coastal geography and dedicated sports initiatives to cultivate talent.",
        sideOptions: [],
      },
      {
        image: punjab,
        value: "Punjab",
        heading: "Punjab",
        link: `/teams/${formatLink("Punjab")}`,
        description:
          "Punjab is a sporting hub, especially in hockey and athletics, producing numerous national and international athletes.",
        sideOptions: [],
      },
      {
        image: rajasthan,
        value: "Rajasthan",
        heading: "Rajasthan",
        link: `/teams/${formatLink("Rajasthan")}`,
        description:
          "Rajasthan has a rich sports culture, with notable achievements in athletics, cricket, and traditional sports like kho-kho.",
        sideOptions: [],
      },
      {
        image: sikkim,
        value: "Sikkim",
        heading: "Sikkim",
        link: `/teams/${formatLink("Sikkim")}`,
        description:
          "Sikkim is renowned for its football prowess, producing talented players and teams that compete at high levels.",
        sideOptions: [],
      },
      {
        image: sscb,
        value: "sscb",
        heading: "Services Sports Control Board",
        link: `/teams/${formatLink("sscb")}`,
        description:
          "Services Sports Control Board (SSCB) excels in multiple disciplines such as athletics, wrestling, and boxing, focusing on comprehensive sports development and training for service personnel.",
        sideOptions: [],
      },
      {
        image: TN,
        value: "Tamil Nadu",
        heading: "Tamil Nadu",
        link: `/teams/${formatLink("Tamil Nadu")}`,
        description:
          "Tamil Nadu excels in sports like cricket, chess, and athletics, supported by a well-developed sports infrastructure.",
        sideOptions: [],
      },
      {
        image: Telangana,
        value: "Telangana",
        heading: "Telangana",
        link: `/teams/${formatLink("Telangana")}`,
        description:
          "Telangana has a growing sports culture, particularly strong in badminton and athletics, fostering young talent.",
        sideOptions: [],
      },
      {
        image: Tripura,
        value: "Tripura",
        heading: "Tripura",
        link: `/teams/${formatLink("Tripura")}`,
        description:
          "Tripura is known for producing top gymnasts and weightlifters, with a strong emphasis on developing young athletes.",
        sideOptions: [],
      },
      {
        image: UP,
        value: "Uttar Pradesh",
        heading: "Uttar Pradesh",
        link: `/teams/${formatLink("Uttar Pradesh")}`,
        description:
          "Uttar Pradesh has a vibrant sports scene, excelling in sports like wrestling, athletics, and cricket.",
        sideOptions: [],
      },
      {
        image: UK,
        value: "uttarakhand",
        heading: "Uttarakhand",
        link: `/teams/${formatLink("uttarakhand")}`,
        description:
          "mountaineering and trekking, utilizing its rugged landscape and Himalayan peaks to promote adventure sports.",
        sideOptions: [],
      },
      {
        image: WB,
        value: "westbengal",
        heading: "West Bengal",
        link: `/teams/${formatLink("westbengal")}`,
        description:
          "West Bengal excels in football and cricket, with a rich sports culture and infrastructure supporting these popular sports across the state.",
        sideOptions: [],
      },
    ],
  },
  {
    img: teamstates,
    value: "States",
    heading: "States",
    link: "/teams",
    description:
      `The "States" section of the 38th National Games Uttarakhand website will feature a comprehensive overview of each state participating in the games, focusing on their unique cultural and sporting heritage. This section will include detailed profiles of each state, covering their history in the National Games, key achievements, and prominent athletes.`,
    sideOptions: [
      {
        image: andhra_pradesh,
        value: "Andhra Pradesh",
        heading: "Andhra Pradesh",
        link: `/teams/${formatLink("Andhra Pradesh")}`,
        description:
          "Andhra Pradesh is known for its rich cultural heritage and vibrant sports community, excelling in athletics and badminton.",
        sideOptions: [],
      },
      {
        image: arunachal_pradesh,
        value: "Arunachal Pradesh",
        heading: "Arunachal Pradesh",
        link: `/teams/${formatLink("Arunachal Pradesh")}`,
        description:
          "Arunachal Pradesh boasts a diverse landscape, fostering a range of sports like archery and football amidst its natural beauty.",
        sideOptions: [],
      },
      {
        image: assam,
        value: "Assam",
        heading: "Assam",
        link: `/teams/${formatLink("Assam")}`,
        description:
          "Assam has a strong sporting tradition, particularly in sports like boxing and weightlifting, producing several national champions.",
        sideOptions: [],
      },
      {
        image: bihar,
        value: "Bihar",
        heading: "Bihar",
        link: `/teams/${formatLink("Bihar")}`,
        description:
          "Assam has a strong sporting tradition, particularly in sports like boxing and weightlifting, producing several national champions.",
        sideOptions: [],
      },
      {
        image: chattisgarh,
        value: "Chhattisgarh",
        heading: "Chhattisgarh",
        link: `/teams/${formatLink("chattisgarh")}`,
        description:
          "Chhattisgarh's sports culture is evolving, with a focus on sports like hockey and archery, reflecting its tribal heritage.",
        sideOptions: [],
      },
      {
        image: Goa,
        value: "Goa",
        heading: "Goa",
        link: `/teams/${formatLink("Goa")}`,
        description:
          "Goa is renowned for its football fervor, producing talented players and teams that compete at national and international levels.",
        sideOptions: [],
      },
      {
        image: Gujarat,
        value: "Gujarat",
        heading: "Gujarat",
        link: `/teams/${formatLink("Gujarat")}`,
        description:
          "Gujarat has a dynamic sports environment, with strengths in cricket, athletics, and traditional sports like kabaddi.",
        sideOptions: [],
      },
      {
        image: Haryana,
        value: "Haryana",
        heading: "Haryana",
        link: `/teams/${formatLink("Haryana")}`,
        description:
          "Haryana is a powerhouse in sports, especially wrestling, boxing, and kabaddi, producing many Olympians and champions.",
        sideOptions: [],
      },
      {
        image: Himanchal,
        value: "Himachal Pradesh",
        heading: "Himachal Pradesh",
        link: `/teams/${formatLink("Himachal Pradesh")}`,
        description:
          "Himachal Pradesh excels in winter sports and athletics, leveraging its mountainous terrain for sports development.",
        sideOptions: [],
      },
      {
        image: jharkhand,
        value: "Jharkhand",
        heading: "Jharkhand",
        link: `/teams/${formatLink("Jharkhand")}`,
        description:
          "Jharkhand has a rich tradition in sports like hockey and archery, producing many athletes who excel on national stages.",
        sideOptions: [],
      },
      {
        image: KRNTK,
        value: "Karnataka",
        heading: "Karnataka",
        link: `/teams/${formatLink("Karnataka")}`,
        description:
          "Karnataka is a leader in various sports, including cricket, badminton, and swimming, with a robust sports infrastructure.",
        sideOptions: [],
      },
      {
        image: KERELA,
        value: "Kerala",
        heading: "Kerala",
        link: `/teams/${formatLink("Kerala")}`,
        description:
          "Kerala is known for its excellence in athletics, football, and volleyball, consistently producing top-tier athletes.",
        sideOptions: [],
      },
      {
        image: MP,
        value: "Madhya Pradesh",
        heading: "Madhya Pradesh",
        link: `/teams/${formatLink("Madhya Pradesh")}`,
        description:
          "Madhya Pradesh has a vibrant sports scene, particularly in archery, athletics, and hockey, fostering young talent.",
        sideOptions: [],
      },
      {
        image: MH,
        value: "Maharashtra",
        heading: "Maharashtra",
        link: `/teams/${formatLink("Maharashtra")}`,
        description:
          "Maharashtra excels in a wide range of sports, from cricket and badminton to athletics, supported by excellent facilities.",
        sideOptions: [],
      },
      {
        image: Manipur,
        value: "Manipur",
        heading: "Manipur",
        link: `/teams/${formatLink("Manipur")}`,
        description:
          "Manipur is a sports powerhouse in the Northeast, known for producing top athletes in boxing, weightlifting, and football.",
        sideOptions: [],
      },
      {
        image: Meghalaya,
        value: "Meghalaya",
        heading: "Meghalaya",
        link: `/teams/${formatLink("Meghalaya")}`,
        description:
          "Meghalaya is passionate about football and archery, with a strong community support for developing sports talent.",
        sideOptions: [],
      },
      {
        image: Mizoram,
        value: "Mizoram",
        heading: "Mizoram",
        link: `/teams/${formatLink("Mizoram")}`,
        description:
          "Mizoram has a vibrant sports culture, particularly excelling in football and hockey, with a strong grassroots development.",
        sideOptions: [],
      },
      {
        image: Nagaland,
        value: "Nagaland",
        heading: "Nagaland",
        link: `/teams/${formatLink("Nagaland")}`,
        description:
          "Nagaland is making its mark in sports like archery, football, and athletics, with increasing participation and success.",
        sideOptions: [],
      },
      {
        image: Odisha,
        value: "Odisha",
        heading: "Odisha",
        link: `/teams/${formatLink("Odisha")}`,
        description:
          "Odisha is known for its strong hockey tradition, as well as growing prominence in athletics and archery.",
        sideOptions: [],
      },
      {
        image: punjab,
        value: "Punjab",
        heading: "Punjab",
        link: `/teams/${formatLink("Punjab")}`,
        description:
          "Punjab is a sporting hub, especially in hockey and athletics, producing numerous national and international athletes.",
        sideOptions: [],
      },
      {
        image: rajasthan,
        value: "Rajasthan",
        heading: "Rajasthan",
        link: `/teams/${formatLink("Rajasthan")}`,
        description:
          "Rajasthan has a rich sports culture, with notable achievements in athletics, cricket, and traditional sports like kho-kho.",
        sideOptions: [],
      },
      {
        image: sikkim,
        value: "Sikkim",
        heading: "Sikkim",
        link: `/teams/${formatLink("Sikkim")}`,
        description:
          "Sikkim is renowned for its football prowess, producing talented players and teams that compete at high levels.",
        sideOptions: [],
      },
      // {
      //   image: sscb,
      //   value: "sscb",
      //   heading: "Services Sports Control Board",
      //   link: `/teams/${formatLink("sscb")}`,
      //   description:
      //     "Services Sports Control Board (SSCB) excels in multiple disciplines such as athletics, wrestling, and boxing, focusing on comprehensive sports development and training for service personnel.",
      //   sideOptions: [],
      // },
      {
        image: TN,
        value: "Tamil Nadu",
        heading: "Tamil Nadu",
        link: `/teams/${formatLink("Tamil Nadu")}`,
        description:
          "Tamil Nadu excels in sports like cricket, chess, and athletics, supported by a well-developed sports infrastructure.",
        sideOptions: [],
      },
      {
        image: Telangana,
        value: "Telangana",
        heading: "Telangana",
        link: `/teams/${formatLink("Telangana")}`,
        description:
          "Telangana has a growing sports culture, particularly strong in badminton and athletics, fostering young talent.",
        sideOptions: [],
      },
      {
        image: Tripura,
        value: "Tripura",
        heading: "Tripura",
        link: `/teams/${formatLink("Tripura")}`,
        description:
          "Tripura is known for producing top gymnasts and weightlifters, with a strong emphasis on developing young athletes.",
        sideOptions: [],
      },
      {
        image: UP,
        value: "Uttar Pradesh",
        heading: "Uttar Pradesh",
        link: `/teams/${formatLink("Uttar Pradesh")}`,
        description:
          "Uttar Pradesh has a vibrant sports scene, excelling in sports like wrestling, athletics, and cricket.",
        sideOptions: [],
      },
      {
        image: UK,
        value: "uttarakhand",
        heading: "Uttarakhand",
        link: `/teams/${formatLink("uttarakhand")}`,
        description:
          "mountaineering and trekking, utilizing its rugged landscape and Himalayan peaks to promote adventure sports.",
        sideOptions: [],
      },
      {
        image: WB,
        value: "westbengal",
        heading: "West Bengal",
        link: `/teams/${formatLink("westbengal")}`,
        description:
          "West Bengal excels in football and cricket, with a rich sports culture and infrastructure supporting these popular sports across the state.",
        sideOptions: [],
      },
    ],
  },
  {
    img: TeamUT,
    value: "Union Territories",
    heading: "Union Territories",
    link: "/teams",
    description:
    `The "Union Territories" section of the 38th National Games Uttarakhand website will provide a focused overview of the union territories taking part in the games. This section will offer profiles of each union territory, showcasing their unique contributions to the National Games and highlighting their sporting traditions.`,
    sideOptions: [
      {
        image: andaman_nicobar,
        value: "Andaman and Nicobar Islands",
        heading: "Andaman and Nicobar Islands",
        link: `/teams/${formatLink("Andaman and Nicobar Islands")}`,
        description:
          "Andaman and Nicobar Islands excel in water sports, leveraging their pristine beaches and clear waters to develop activities like scuba diving, snorkeling, and sailing..",
        sideOptions: [],
      },
      {
        image: chandigarh,
        value: "chandigarh",
        heading: "Chandigarh",
        link: `/teams/${formatLink("chandigarh")}`,
        description:
          "Chandigarh excels in athletics and hockey, benefiting from its well-planned infrastructure and sports facilities that nurture talent in these disciplines..",
        sideOptions: [],
      },
      {
        image: DadraAndnagarhaveli,
        value: "Dadra and Nagar Haveli and Daman and Diu",
        heading: "Dadra and Nagar Haveli and Daman and Diu",
        link: `/teams/${formatLink(
          "Dadra and Nagar Haveli and Daman and Diu"
        )}`,
        description:
          "Dadra and Nagar Haveli and Daman and Diu excel in traditional sports and athletics, utilizing community participation and local enthusiasm to foster a strong sports culture.",
        sideOptions: [],
      },
      {
        image: Lakshadweep,
        value: "Lakshadweep",
        heading: "Lakshadweep",
        link: `/teams/${formatLink("Lakshadweep")}`,
        description:
          "Lakshadweep excels in water sports, making the most of its natural aquatic environment to develop and promote activities such as kayaking, windsurfing, and diving.",
        sideOptions: [],
      },
      {
        image: Delhi,
        value: "Delhi",
        heading: "Delhi",
        link: `/teams/${formatLink("Delhi")}`,
        description:
          "Delhi, the capital city, is a hub for various sports, excelling in athletics, cricket, and boxing, with state-of-the-art facilities.",
        sideOptions: [],
      },
      {
        image: Puducherry,
        value: "Puducherry",
        heading: "Puducherry",
        link: `/teams/${formatLink("Puducherry")}`,
        description:
          "Puducherry excels in beach volleyball and athletics, leveraging its coastal geography and dedicated sports initiatives to cultivate talent.",
        sideOptions: [],
      },
      {
        image: J_K,
        value: "Jammu & Kashmir",
        heading: "Jammu & Kashmir",
        link: `/teams/${formatLink("Jammu & Kashmir")}`,
        description:
          "Jammu & Kashmir is known for winter sports like skiing and ice hockey, along with a growing interest in cricket and football.",
        sideOptions: [],
      },
      {
        image: ladakh,
        value: "Ladakh",
        heading: "Ladakh",
        link: `/teams/${formatLink("ladakh")}`,
        description:
          "Ladakh excels in adventure sports and archery, using its high-altitude environment to develop sports that reflect its unique cultural and geographical context.",
        sideOptions: [],
      },
    ],
  },
  {
    img: SportsBoard,
    value: "Sports Boards",
    heading: "Sports Boards",
    link: "/teams",
    description:
      `The "Sports Boards" section of the 38th National Games Uttarakhand website will provide a detailed and organized overview of the various sports boards participating in the event. This section will feature profiles of each sports board, including their roles, history, and contributions to the National Games.`,
    sideOptions: [
      {
        image: sscb,
        value: "sscb",
        heading: "Services Sports Control Board",
        link: `/teams/${formatLink("sscb")}`,
        description:
          "Services Sports Control Board (SSCB) excels in multiple disciplines such as athletics, wrestling, and boxing, focusing on comprehensive sports development and training for service personnel.",
        sideOptions: [],
      },
    ],
  },
];

// Helper function to format state name for URL
function formatLink(name) {
  // Replace spaces with dashes and convert to lowercase
  return name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
}

export const mileStone_data = [
  {
    img: allMilestone,
    value: "All",
    heading: "All",
    link: "/milestone/all",
    description:
      "Explore the key milestones of the 38th National Games, from the initial planning stages to the grand events that showcased India's sporting excellence. Each milestone represents a significant achievement in the journey towards a successful and memorable National Games, celebrating unity, sportsmanship, and competitive spirit.",

    sideOptions: [
      {
        image: IOAAnnounces,
        link: { isRelativePath: true, path: "/" },
        description:
          "IOA Announces Uttarakhand as Host State for National Games of India 2024",
      },
      {
        image: NG38,
        link: { isRelativePath: true, path: "/" },
        description:
          "The 38th National Games Organising Committee [NGOC] for Uttarakhand Games 2024 is Established",
      },
      {
        image: flagPassed,
        link: { isRelativePath: true, path: "/" },
        description:
          "The Flag is Passed from the 37th National Games Organising Committee [GOA] to the 38th National Games Organising Committee ",
      },
      {
        image: logolaunch,
        link: { isRelativePath: true, path: "/" },
        description: "Logo Launch Ceremony",
      },
      {
        image: Rocket3,
        link: { isRelativePath: true, path: "/" },
        description:
          "The Official Games Management System and Website Launch Ceremony for Uttarakhand Games Organising Committee [UKGOC] ",
      },
      {
        image: rocket4,
        link: { isRelativePath: true, path: "/" },
        description: "Slogan of Uttarakhand Games 2024 is Released",
      },
      {
        image: Medal,
        link: { isRelativePath: true, path: "/" },
        description: "Sports and Kinetic Pictogram Launch Ceremony",
      },
      {
        image: Victory,
        link: { isRelativePath: true, path: "/" },
        description: "Mascot Launch Ceremony",
      },
      {
        image: Rocket3,
        link: { isRelativePath: true, path: "/" },
        description: "Graphics and Colour System Release",
      },
      {
        image: Medal,
        link: { isRelativePath: true, path: "/" },
        description:
          "Medal of the 38th Uttarakhand National Games 2024 Unveiled",
      },
      {
        image: Victory,
        link: { isRelativePath: true, path: "/" },
        description: "Victory Bouquet, Medal Tray, and Podium Released",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Anthem Launch",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Torch of 38th Uttarakhand National Games 2024 Launched",
      },
    ],
  },
  {
    img: torch,
    value: "Opening Ceremony",
    heading: "Opening Ceremony",
    link: "/",
    description:
      "The Opening Ceremony of the 38th National Games set the stage for a grand celebration of sportsmanship and culture. With vibrant performances, the event marked the official start, uniting athletes and spectators in an inspiring display of national pride and competitive spirit.",
    sideOptions: [],
  },
  {
    img: torch,
    value: "Closing Ceremony",
    heading: "Closing Ceremony",
    link: "/",
    description:
      "The Closing Ceremony of the 38th National Games was a fitting finale, celebrating the achievements of athletes and the spirit of competition. The event featured a blend of cultural performances and emotional farewells, marking the conclusion of a successful and memorable sporting event.",
    sideOptions: [],
  },
];

//   News
// Photos
// Videos
export const latest_data = [
  {
    img: news,
    value: "News",
    heading: "News",
    link: "/latest/news",
    description:
      "Stay informed with the latest updates and developments from the 38th National Games. From breaking stories to in-depth analysis, our news section covers every aspect of the Games, ensuring you don't miss a beat of the action.",
    sideOptions: [],
  },
  {
    img: gallery,
    value: "Photos",
    heading: "Photos",
    link: "/latest/photos",
    description:
      "Explore captivating moments from the 38th National Games through our curated photo gallery. Each image captures the excitement, emotion, and spirit of the athletes, offering a visual journey through the competition..",
    sideOptions: [],
  },
  {
    img: videos,
    value: "Videos",
    heading: "Videos",
    link: "/latest/videos",
    description:
      "Experience the 38th National Games in motion with our video highlights. Watch key moments, interviews, and behind-the-scenes footage that bring the energy and intensity of the Games to life.",
    sideOptions: [],
  },
  {
    img: allMilestone,
    value: "All",
    heading: "MILESTONES",
    link: "/milestone/all",
    description:
      "Explore the key milestones of the 38th National Games, from the initial planning stages to the grand events that showcased India's sporting excellence. Each milestone represents a significant achievement in the journey towards a successful and memorable National Games, celebrating unity, sportsmanship, and competitive spirit.",

    sideOptions: [
      {
        image: IOAAnnounces,
        link: { isRelativePath: true, path: "/" },
        description:
          "IOA Announces Uttarakhand as Host State for National Games of India 2024",
      },
      {
        image: NG38,
        link: { isRelativePath: true, path: "/" },
        description:
          "The 38th National Games Organising Committee [NGOC] for Uttarakhand Games 2024 is Established",
      },
      {
        image: flagPassed,
        link: { isRelativePath: true, path: "/" },
        description:
          "The Flag is Passed from the 37th National Games Organising Committee [GOA] to the 38th National Games Organising Committee ",
      },
      {
        image: logolaunch,
        link: { isRelativePath: true, path: "/" },
        description: "Logo Launch Ceremony",
      },
      {
        image: Rocket3,
        link: { isRelativePath: true, path: "/" },
        description:
          "The Official Games Management System and Website Launch Ceremony for Uttarakhand Games Organising Committee [UKGOC] ",
      },
      {
        image: rocket4,
        link: { isRelativePath: true, path: "/" },
        description: "Slogan of Uttarakhand Games 2024 is Released",
      },
      {
        image: Medal,
        link: { isRelativePath: true, path: "/" },
        description: "Sports and Kinetic Pictogram Launch Ceremony",
      },
      {
        image: Victory,
        link: { isRelativePath: true, path: "/" },
        description: "Mascot Launch Ceremony",
      },
      {
        image: Rocket3,
        link: { isRelativePath: true, path: "/" },
        description: "Graphics and Colour System Release",
      },
      {
        image: Medal,
        link: { isRelativePath: true, path: "/" },
        description:
          "Medal of the 38th Uttarakhand National Games 2024 Unveiled",
      },
      {
        image: Victory,
        link: { isRelativePath: true, path: "/" },
        description: "Victory Bouquet, Medal Tray, and Podium Released",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Anthem Launch",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Torch of 38th Uttarakhand National Games 2024 Launched",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Opening Ceremony",
      },
      {
        image: torch,
        link: { isRelativePath: true, path: "/" },
        description: "Closing Ceremony",
      },
    ],
  },
];
