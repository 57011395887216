import uttrakhandimg from "../../assets/home2/Body/uttrakhandopen.png"
import cycling from "../../assets/home2/Cluster/cycling.png"
import badminton from "../../assets/home2/Cluster/badminton.png"
import handball from "../../assets/home2/Cluster/handball.png"
import rowing from "../../assets/home2/Cluster/rowing.png"
import shooting from "../../assets/home2/Cluster/shooting.png"
import volleyball from "../../assets/home2/Cluster/volleyball.png"
import hockey from "../../assets/home2/Cluster/hockey.png"
import fencing from "../../assets/home2/Cluster/fencing.png"
import judo from "../../assets/home2/Cluster/judo.png"
import golf from "../../assets/home2/Cluster/golf.png"
import gymnastic from "../../assets/home2/Cluster/gymnastic.png"
import billiards from "../../assets/home2/Cluster/billiard-and-snooker.png"
import boxing from "../../assets/home2/Cluster/boxing.png"
import basketball from "../../assets/home2/Cluster/Basketball.png"
import football from "../../assets/home2/Cluster/football.png";
import rollBall from "../../assets/home2/Cluster/roll-ball.png"
import Sepak from "../../assets/home2/Cluster/sepak-takraw.png"
import squay from "../../assets/home2/Cluster/sqay-martial-art.png"
import weightlifting from "../../assets/home2/Cluster/weightlifting.png"
import pencak from "../../assets/home2/Cluster/Pencak-Silat.png"
import mallkhamb from "../../assets/home2/Cluster/mallakhamb.png";
import rugby from "../../assets/home2/Cluster/Rugby.png"
import kabaddi from "../../assets/home2/Cluster/kabaddi.png"
import aquatics from "../../assets/home2/Cluster/Aquatics.png"
import gatka from "../../assets/home2/Cluster/gatka.png"
import wrestling from "../../assets/home2/Cluster/wrestling.png"
import athletics from "../../assets/home2/Cluster/Athletics.png"
import wushu from "../../assets/home2/Cluster/wushu.png"
import triathlon from "../../assets/home2/Cluster/Trithlon.png"
import yatching from "../../assets/home2/Cluster/yachting.png"
import tableTennis from "../../assets/home2/Cluster/table-tennis.png"
import yoga from "../../assets/home2/Cluster/yoga.png"
import lagori from "../../assets/home2/Cluster/lagori.png"
import kallaripayat from "../../assets/home2/Cluster/kallaripayattu.png"
import modernPentathlon from "../../assets/home2/Cluster/Modern-Pentathlon.png"
import archery from "../../assets/home2/Cluster/Archery.png";
import taekondo from "../../assets/home2/Cluster/taekwondo.png";
import khokho from "../../assets/home2/Cluster/kho-kho.png"

export const sportsName = [
    {
        id: 1,
        sportName: "Opening & Closing Ceremonies",
        date: ["26 October 2023", "09 November 2023"],
        startDate: null,
        endDate: null,
        img: uttrakhandimg,

    },
    {
        id: 2,
        sportName: "Badminton",
        startDate: "19 October 2023",
        endDate: "24 October 2023",
        routing: "badminton",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
          
    },
    {
        id: 3,
        sportName: "Netball",
        startDate: "22 October 2023",
        endDate: "27 October 2023",
        routing: "netball",
        scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 4,
        sportName: "Basketball",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "basketball",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 5,
        sportName: "Gymnastics",
        startDate: "23 October 2023",
        endDate: "28 October 2023",
        routing: "gymnasium",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 6,
        sportName: "Rugby",
        startDate: "25 October 2023",
        endDate: "27 October 2023",
        routing: "rugby",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 7,
        sportName: "Weightlifting",
        startDate: "25 October 2023",
        endDate: "29 October 2023",
        routing: "weightlifting",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 8,
        sportName: "Mallakhamb",
        startDate: "26 October 2023",
        endDate: "28 October 2023",
        routing: "mallakhamb",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 9,
        sportName: "Pencak Silat",
        startDate: "26 October 2023",
        endDate: "29 October 2023",
        key: "pencak-silat",
        routing: "pencak-silat",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {

        id: 10,
        sportName: "Modern Pentathlon",
        startDate: "26 October 2023",
        endDate: "29 October 2023",
        routing: "modern-pentathlon",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 11,
        sportName: "Fencing",
        startDate: "26 October 2023",
        endDate: "30 October 2023",
        routing: "fencing",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 12,
        sportName: "Billiards & Snookers",
        startDate: "27 October 2023",
        endDate: "30 October 2023",
        key: "billiard-&-snooker",
        routing: "billiard-&-snooker",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 13,
        sportName: "Football (Women)",
        startDate: "27 October 2023",
        endDate: "5 November 2023",
        routing: "football",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 14,
        sportName: "Rowing",
        startDate: "28 October 2023",
        endDate: "1 November 2023",
        routing: "rowing",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 15,
        sportName: "Beach Football",
        startDate: "28 October 2023",
        endDate: "1 November 2023",
        routing: "beach-football",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 16,
        sportName: "Table Tennis",
        startDate: "29 October 2023",
        endDate: "2 November 2023",
        routing: "table-tennis",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 17,
        sportName: "Athletics",
        startDate: "29 October 2023",
        endDate: "3 November 2023",
        routing: "athletics",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 18,
        sportName: "Aquatics",
        startDate: "29 October 2023",
        endDate: "4 November 2023",
        routing: "aquatics",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 19,
        sportName: "Archery",
        startDate: "29 October 2023",
        endDate: "6 November 2023",
        routing: "archery",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 20,
        sportName: "Rollball",
        startDate: "30 October 2023",
        endDate: "2 November 2023",
        routing: "rollball",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 21,
        sportName: "Sepak Takraw",
        startDate: "30 October 2023",
        endDate: "3 November 2023",
        key: "sepak-takraw",
        routing: "sepak-takraw",
          scheduleCard: [
            {
              "id": 1,
              "activeDate": true
            },
            {
              "id": 2,
              "activeDate": true
            },
            {
              "id": 3,
              "activeDate": true
            },
            {
              "id": 4,
              "activeDate": true
            },
            {
              "id": 5,
              "activeDate": true
            },
            {
              "id": 6,
              "activeDate": true
            },
            {
              "id": 7,
              "activeDate": true
            },
            {
              "id": 8,
              "activeDate": true
            },
            {
              "id": 9,
              "activeDate": true
            },
            {
              "id": 10,
              "activeDate": true
            },
            {
              "id": 11,
              "activeDate": true
            },
            {
              "id": 12,
              "activeDate": true
            },
            {
              "id": 13,
              "activeDate": true
            },
            {
              "id": 14,
              "activeDate": true
            },
            {
              "id": 15,
              "activeDate": true
            }
          ]
    },
    {
        id: 22,
        sportName: "Lawn Tennis",
        startDate: "30 October 2023",
        endDate: "5 November 2023",
        routing: "lawn-tennis",
    },
    {
        id: 23,
        sportName: "Hockey",
        startDate: "30 October 2023",
        endDate: "8 November 2023",
        routing: "hockey",
    },
    {
        id: 24,
        sportName: "Football (Men)",
        startDate: "30 October 2023",
        endDate: "8 November 2023",
        routing: "football",
    },
    {
        id: 25,
        sportName: "Gatka",
        startDate: "31 October 2023",
        endDate: "2 November 2023",
        routing: "gatka",

    },
    {
        id: 26,
        sportName: "Taekwondo",
        startDate: "31 October 2023",
        endDate: "3 November 2023",
        routing: "taekwondo",
    },
    {
        id: 27,
        sportName: "Mini Golf",
        startDate: "1 November 2023",
        endDate: "3 November 2023",
        routing: "mini-golf",
    },
    {
        id: 28,
        sportName: "Wrestling",
        startDate: "1 November 2023",
        endDate: "3 November 2023",
        routing: "wrestling",
    },
    {
        id: 29,
        sportName: "Wushu",
        startDate: "1 November 2023",
        endDate: "4 November 2023",
        routing: "wushu",
    },
    {
        id: 30,
        sportName: "Volleyball",
        startDate: "1 November 2023",
        endDate: "5 November 2023",
        routing: "volleyball",
    },
    {
        id: 31,
        sportName: "Squash",
        startDate: "1 November 2023",
        endDate: "5 November 2023",
        routing: "squash",
    },
    {
        id: 32,
        sportName: "Lawn Bowls",
        startDate: "1 November 2023",
        endDate: "8 November 2023",
        key: "lawn-bowl",
        routing: "lawn-bowl",
    },
    {
        id: 33,
        sportName: "Boxing",
        startDate: "1 November 2023",
        endDate: "8 November 2023",
        routing: "boxing",
    },
    {
        id: 34,
        sportName: "Cycling (Track Race)",
        startDate: "2 November 2023",
        endDate: "5 November 2023",
        routing: "cycling",
    },
    {
        id: 35,
        sportName: "Shooting",
        startDate: "2 November 2023",
        endDate: "9 November 2023",
        routing: "shooting",
    },
    {
        id: 36,
        sportName: "Rowing (Coastal/Tidal Sport)",
        startDate: "3 November 2023",
        endDate: "6 November 2023",
        routing: "rowing",
    },
    {
        id: 37,
        sportName: "Yachting",
        startDate: "3 November 2023",
        endDate: "8 November 2023",
        routing: "yachting",
    },
    {
        id: 38,
        sportName: "Triathlon",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "triathlon",
    },
    {
        id: 40,
        sportName: "Canoeing & Kayaking",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "canoeing-&-kayaking",
    },
    {
        id: 41,
        sportName: "Beach Volleyball",
        startDate: "4 November 2023",
        endDate: "7 November 2023",
        routing: "beach-volleyball",
    },
    {
        id: 42,
        sportName: "Handball",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "handball",
    },
    {
        id: 43,
        sportName: "Kabaddi",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "kabaddi",
    },
    {
        id: 44,
        sportName: "Kho-Kho",
        startDate: "4 November 2023",
        endDate: "8 November 2023",
        routing: "kho-kho",
    },
    {
        id: 45,
        sportName: "Lagori",
        startDate: "5 November 2023",
        endDate: "6 November 2023",
        routing: "lagori",
    },
    {
        id: 46,
        sportName: "Beach Handball",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "beach-handball",
    },
    {
        id: 47,
        sportName: "Golf",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "golf",
    },
    {
        id: 48,
        sportName: "Yogasana",
        startDate: "5 November 2023",
        endDate: "9 November 2023",
        routing: "yogasana",
    },
    {
        id: 49,
        sportName: "Sqay Martial Arts",
        startDate: "6 November 2023",
        endDate: "8 November 2023",
        key: "sqay-martial-arts",
        routing: "sqay-martial-arts",
    },
    {
        id: 50,
        sportName: "Judo",
        startDate: "6 November 2023",
        endDate: "8 November 2023",
        routing: "judo",
    },
    {
        id: 51,
        sportName: "Kalaripayattu",
        startDate: "7 November 2023",
        endDate: "8 November 2023",
        routing: "kalaripayattu",
    },
    {
        id: 52,
        sportName: "Cycling (Road Race)",
        startDate: "8 November 2023",
        endDate: "9 November 2023",
        routing: "cycling",
    },
];

export const dateData = [
    {
      id: 1,
      date: 25,
      day: "Tue"
    },
    {
      id: 2,
      date: 26,
      day: "Wed"
    },
    {
      id: 3,
      date: 27,
      day: "Thu"
    },
    {
      id: 4,
      date: 28,
      day: "Fri"
    },
    {
      id: 5,
      date: 29,
      day: "Sat"
    },
    {
      id: 6,
      date: 30,
      day: "Sun"
    },
    {
      id: 7,
      date: 31,
      day: "Mon"
    },
    {
      id: 8,
      date: 1,
      day: "Tue"
    },
    {
      id: 9,
      date: 2,
      day: "Wed"
    },
    {
      id: 10,
      date: 3,
      day: "Thu"
    },
    {
      id: 11,
      date: 4,
      day: "Fri"
    },
    {
      id: 12,
      date: 5,
      day: "Sat"
    },
    {
      id: 13,
      date: 6,
      day: "Sun"
    },
    {
      id: 14,
      date: 7,
      day: "Mon"
    },
    {
      id: 15,
      date: 8,
      day: "Tue"
    }
  ];


  export const clusterdatalist = [
    {
        id: 1,
        clusterHeading: "Dehradun",
        clusterSportList: [
          {
            id: 1,
             sportimg:cycling,
             sportName:"Cycling (Track Race)",
             sportplace:"Dehradun",
             sportaddress:"Indira Gandhi Stadium Complex, New Delhi",
             sportstatingData:"2 Nov 2023",
             sportendData:"2 Nov 2023",

          },
          {
            id:2,
             sportimg:cycling,
             sportName:"Golf",
             sportplace:"Dehradun",
             sportaddress:"TBD",
             sportstatingData:"5 Nov 2023",
             sportendData:"9 Nov 2023",

          },
          
         
        ]

    },
    {
      id: 2,
      clusterHeading: "Rishikesh",
      clusterSportList: [
        {
          id: 1,
           sportimg:shooting,
           sportName:"Shooting",
           sportplace:"Rishikesh",
           sportaddress:"Yash Shooting Academy",
           sportstatingData:"1 Nov 2023",
           sportendData:"8 Nov 2023",

        },
       
      ]

     },
     {
      id: 3,
      clusterHeading: "Haridwar",
      clusterSportList: [
        {
          id: 1,
           sportimg:gymnastic,
           sportName:"Gymnastics",
           sportplace:"Haridwar",
           sportaddress:"Peddem Multi-Purpose Indoor Stadium",
           sportstatingData:"23 Oct 2023",
           sportendData:"28 Oct 2023",

        },
        {
          id: 2,
           sportimg: billiards,
           sportName:"Billiards & Snookers",
           sportplace:"Haridwar",
           sportaddress:"Indoor Hall A,  Peddem Sports Complex",
           sportstatingData:"27 Oct 2023",
           sportendData:"30 Oct 2023",

        },
        {
          id: 3,
           sportimg:rowing,
           sportName:"Rowing ",
           sportplace:"Haridwar",
           sportaddress:"Chapora River ",
           sportstatingData:"27 Oct 2023",
           sportendData:"31 Oct 2023",

        },
        {
          id: 4,
           sportimg:hockey,
           sportName:"Hockey",
           sportplace:"Haridwar",
           sportaddress:"Hockey Ground, Peddem Sports Complex",
           sportstatingData:"29 Oct 2023",
           sportendData:"8 Nov 2023",

        },
        {
          id: 5,
           sportimg:boxing,
           sportName:"Boxing",
           sportplace:"Haridwar",
           sportaddress:"Peddem Multi-Purpose Indoor Stadium",
           sportstatingData:"23 Oct 2023",
           sportendData:"28 Oct 2023",

        },
       
      ]

     },
     {
      id: 4,
      clusterHeading: "Tehri",
      clusterSportList: [
        {
          id: 1,
           sportimg:basketball,
           sportName:"Basketball",
           sportplace:"Tehri",
           sportaddress:"Manohar Parrikar Indoor Stadium, Navelim",
           sportstatingData:"23 Oct 2023",
           sportendData:"28 Oct 2023",

        },
        {
          id: 2,
           sportimg:football,
           sportName:"Football (Men)",
           sportplace:"Tehri",
           sportaddress:"PJN Stadium, Fatorda",
           sportstatingData:"29 Oct 2023",
           sportendData:"7 Nov 2023",

        },
        {
          id: 3,
           sportimg:rollBall,
           sportName:"Rollball",
           sportplace:"Tehri",
           sportaddress:"Manohar Parrikar Indoor Stadium, Navelim",
           sportstatingData:"30 Oct 2023",
           sportendData:"2 Nov 2023",

        },
        {
          id: 4,
           sportimg:Sepak,
           sportName:"Sepak Takraw",
           sportplace:"Tehri",
           sportaddress:"Fatorda Multi-Purpose Hall",
           sportstatingData:"30 Oct 2023",
           sportendData:"3 Nov 2023",

        },
        {
          id: 5,
           sportimg:handball,
           sportName:"Handball",
           sportplace:"Tehri",
           sportaddress:"Manohar Parrikar Indoor Stadium, Navelim",
           sportstatingData:"4 Nov 2023",
           sportendData:"8 Nov 2023",

        },
        {
          id: 6,
           sportimg:squay,
           sportName:"Sqay Martial Arts ",
           sportplace:"Tehri",
           sportaddress:"Fatorda Multi-Purpose Hall",
           sportstatingData:"6 Nov 2023",
           sportendData:"8  Nov 2023",

        },
        {
          id: 7,
           sportimg:cycling,
           sportName:"Cycling (Road Race)",
           sportplace:"Tehri",
           sportaddress:"Verna - Birla Bypass Airport Road",
           sportstatingData:"8 Nov 2023",
           sportendData:"9 Nov 2023",

        },
       
      ]

     },
     {
      id: 5,
      clusterHeading: "Nainital",
      clusterSportList: [
        {
          id: 1,
           sportimg:badminton,
           sportName:"Badminton",
           sportplace:"Nainital",
           sportaddress:"S.P.M. Stadium, Goa University ",
           sportstatingData:"19 Oct 2023",
           sportendData:"24 Oct 2023",

        },
        {
          id: 2,
           sportimg:weightlifting,
           sportName:"Weightlifting",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"25 Oct 2023",
           sportendData:"29 Oct 2023",

        },
        {
          id: 3,
           sportimg:pencak,
           sportName:"Pencak Silat ",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"26 Oct 2023",
           sportendData:"29 Oct 2023",

        },
        {
          id: 4,
           sportimg:mallkhamb,
           sportName:"Mallakhamb",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"26 Oct 2023",
           sportendData:"28 Oct 2023",

        },
        {
          id: 5,
           sportimg:fencing,
           sportName:"Fencing ",
           sportplace:"Nainital",
           sportaddress:"S.P.M. Stadium, Goa University",
           sportstatingData:"26 Oct 2023",
           sportendData:"30 Oct 2023",

        },
        {
          id: 6,
           sportimg:squay,
           sportName:"Sqay Martial Arts ",
           sportplace:"Tehri",
           sportaddress:"Fatorda Multi-Purpose Hall",
           sportstatingData:"6 Nov 2023",
           sportendData:"8  Nov 2023",

        },
        {
          id: 7,
           sportimg:rugby,
           sportName:"Rugby",
           sportplace:"Nainital",
           sportaddress:"Athletics Stadium, Bambolim",
           sportstatingData:"26 Oct 2023",
           sportendData:"28 Oct 2023",

        },


        {
          id: 8,
           sportimg:kabaddi,
           sportName:"Kabaddi  ",
           sportplace:"Nainital",
           sportaddress:"Campal Multi-Purpose Indoor Stadium ",
           sportstatingData:"29 Oct 2023",
           sportendData:"2 Nov 2023",

        },
        {
          id: 9,
           sportimg:aquatics,
           sportName:"Aquatics ",
           sportplace:"Tehri",
           sportaddress:"Swimming Pool Campal",
           sportstatingData:"29 Oct 2023",
           sportendData:"4 Nov 2023",

        },

        {
          id: 10,
           sportimg:gatka,
           sportName:"Gatka",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"31 Oct 2023",
           sportendData:"2 Nov 2023",

        },
        {
          id: 11,
           sportimg:wrestling,
           sportName:"Wrestling ",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"31 Oct 2023",
           sportendData:"2 Nov 2023",

        },
        {
          id: 13,
           sportimg:athletics,
           sportName:"Athletics  ",
           sportplace:"Nainital",
           sportaddress:"Athletics Stadium, Bambolim",
           sportstatingData:"31 Oct 2023",
           sportendData:"5 Nov 2023",

        },
        {
          id: 14,
           sportimg:wushu,
           sportName:"Wushu",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"1 Nov 2023",
           sportendData:"4 Nov 2023",

        },
        {
          id: 15,
           sportimg:rowing,
           sportName:"Rowing Coastal",
           sportplace:"Nainital",
           sportaddress:"Hawaii Beach, Dona Paula",
           sportstatingData:"2 Nov 2023",
           sportendData:"6 Nov 2023",

        },
        {
          id: 16,
           sportimg:volleyball,
           sportName:"Volleyball",
           sportplace:"Nainital",
           sportaddress:"S.P.M. Stadium, Goa University ",
           sportstatingData:"2 Nov 2023",
           sportendData:"6 Nov 2023",

        },
        {
          id: 17,
           sportimg:triathlon,
           sportName:"Triathlon",
           sportplace:"Nainital",
           sportaddress:"Caranzalem - Miramar Road",
           sportstatingData:"3 Nov 2023",
           sportendData:"7 Nov 2023",

        },
        {
          id: 18,
           sportimg:yatching,
           sportName:"Yachting",
           sportplace:"Nainital",
           sportaddress:"Hawaii Beach, Dona Paula",
           sportstatingData:"3 Nov 2023",
           sportendData:"8 Nov 2023",

        },
        {
          id: 19,
           sportimg:tableTennis,
           sportName:"Table Tennis",
           sportplace:"Nainital",
           sportaddress:"Campal Multi-Purpose Indoor Stadium ",
           sportstatingData:"4 Nov 2023",
           sportendData:"8 Nov 2023",

        },
        {
          id: 20,
           sportimg:yoga,
           sportName:"Yoagasana",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"5 Nov 2023",
           sportendData:"8 Nov 2023",

        },
        {
          id: 21,
           sportimg:cycling,
           sportName:"Cycling (Track Race)",
           sportplace:"Nainital",
           sportaddress:"Indira Gandhi Stadium Complex, New Delhi",
           sportstatingData:"2 Nov 2023",
           sportendData:"5 Nov 2023",

        },
        {
          id: 22,
           sportimg:lagori,
           sportName:"Lagori",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"5 Nov 2023",
           sportendData:"6 Nov 2023",

        },
        {
          id: 23,
           sportimg:judo,
           sportName:"Judo ",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"6 Nov 2023",
           sportendData:"6 Nov 2023",

        },
        {
          id: 23,
           sportimg:kallaripayat,
           sportName:"Kalaripayattu ",
           sportplace:"Nainital",
           sportaddress:"Campal Open Ground (Campal Sports Village)",
           sportstatingData:"7 Nov 2023",
           sportendData:"8 Nov 2023",

        },
       
      ]

     },
     {
      id: 6,
      clusterHeading: "Haldwani",
      clusterSportList: [
        {
          id: 1,
           sportimg:modernPentathlon,
           sportName:"Modern Pentathlon",
           sportplace:"Haldwani",
           sportaddress:"Ponda Multi-Purpose Indoor Stadium",
           sportstatingData:"26 Oct 2023",
           sportendData:"29 Oct 2023",

        },
        {
          id: 2,
           sportimg:archery,
           sportName:"Archery ",
           sportplace:"Haldwani",
           sportaddress:"Goa Engineering Collage, Farmagudi - Ponda",
           sportstatingData:"28 Oct 2023",
           sportendData:"5 Nov 2023",

        },
        {
          id: 3,
           sportimg:taekondo,
           sportName:"Taekwondo",
           sportplace:"Haldwani",
           sportaddress:"Ponda Multi-Purpose Indoor Stadium",
           sportstatingData:"31 Oct 2023",
           sportendData:"3 Nov 2023",

        },
        {
          id: 4,
           sportimg:khokho,
           sportName:"Kho-Kho",
           sportplace:"Haldwani",
           sportaddress:"Ponda Multi-Purpose Indoor Stadium",
           sportstatingData:"4 Nov 2023",
           sportendData:"8 Nov 2023",

        },
      ]

     },
   
   
   
    
   
   
   
   
    
    
    
   
];



export const Dailydatalist = [
  {
      id: 1,
      clusterHeading: "01 Oct",
      dailySportList: [
        {
          id: 1,
           sportimg:badminton,
           sportName:"Badminton",
           sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
           detailstablelist: [
            {
              id: 1,
               sportimg:cycling,
               individual:"Compound Individual ",
               round:"Qualifying Round",
               address:"Indira Gandhi Stadium Complex new Delhi",
               time:"2:45 PM",
               map:"Haryana",
               score:"2-2",
               location:"Karnataka",
               viewscore:"VIEW SCORE"
      
            },
           ]
          

        },
        {
          id: 2,
           sportimg:handball,
           sportName:"Handball",
           sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
           detailstablelist: [
            {
              id: 1,
               sportimg:cycling,
               individual:"Compound Individual ",
               round:"Qualifying Round",
               address:"Indira Gandhi Stadium Complex new Delhi",
               time:"2:45 PM",
               map:"Haryana",
               score:"2-2",
               location:"Karnataka",
               viewscore:"VIEW SCORE"
      
            },
           ]
          

        },
        {
          id: 3,
           sportimg:rowing,
           sportName:"Rowing",
           sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
           detailstablelist: [
            {
              id: 1,
               sportimg:cycling,
               individual:"Compound Individual ",
               round:"Qualifying Round",
               address:"Indira Gandhi Stadium Complex new Delhi",
               time:"2:45 PM",
               map:"Haryana",
               score:"2-2",
               location:"Karnataka",
               viewscore:"VIEW SCORE"
      
            },
           ]
          

        },
        {
          id: 4,
           sportimg:shooting,
           sportName:"Shooting",
           sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
           detailstablelist: [
            {
              id: 1,
               sportimg:cycling,
               individual:"Compound Individual ",
               round:"Qualifying Round",
               address:"Indira Gandhi Stadium Complex new Delhi",
               time:"2:45 PM",
               map:"Haryana",
               score:"2-2",
               location:"Karnataka",
               viewscore:"VIEW SCORE"
      
            },
           ]
          

        },
        
        
       
      ]

  },
  {
    id: 2,
    clusterHeading: "02 Oct",
    dailySportList: [
      {
        id: 1,
         sportimg:volleyball,
         sportName:"Volleyball",
         sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
         detailstablelist: [
          {
            id: 1,
             sportimg:cycling,
             individual:"Compound Individual ",
             round:"Qualifying Round",
             address:"Indira Gandhi Stadium Complex new Delhi",
             time:"2:45 PM",
             map:"Haryana",
             score:"2-2",
             location:"Karnataka",
             viewscore:"VIEW SCORE"
    
          },
         ]
        

      },
      {
        id: 2,
         sportimg:hockey,
         sportName:"Hockey",
         sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
         detailstablelist: [
          {
            id: 1,
             sportimg:cycling,
             individual:"Compound Individual ",
             round:"Qualifying Round",
             address:"Indira Gandhi Stadium Complex new Delhi",
             time:"2:45 PM",
             map:"Haryana",
             score:"2-2",
             location:"Karnataka",
             viewscore:"VIEW SCORE"
    
          },
         ]
        

      },
      {
        id: 3,
         sportimg:fencing,
         sportName:"Fencing",
         sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
         detailstablelist: [
          {
            id: 1,
             sportimg:cycling,
             individual:"Compound Individual ",
             round:"Qualifying Round",
             address:"Indira Gandhi Stadium Complex new Delhi",
             time:"2:45 PM",
             map:"Haryana",
             score:"2-2",
             location:"Karnataka",
             viewscore:"VIEW SCORE"
    
          },
         ]
        

      },
      {
        id:4,
         sportimg:judo,
         sportName:"Judo",
         sportpara:"Mixed Doubles, Women's Singles, Men's Doubles, Women's Doubles, Men's Singles",
         detailstablelist: [
          {
            id: 1,
             sportimg:cycling,
             individual:"Compound Individual ",
             round:"Qualifying Round",
             address:"Indira Gandhi Stadium Complex new Delhi",
             time:"2:45 PM",
             map:"Haryana",
             score:"2-2",
             location:"Karnataka",
             viewscore:"VIEW SCORE"
    
          },
         ]
        

      },
      
     
    ]

},
   
 
 
  
 
 
 
 
  
  
  
 
];
  